.nav-div{
    min-width: 100%;
    background-color: #323740 !important;
    border: none !important;
    border-radius: 0% !important;
    color:hsl(209, 71%, 44%) !important;
}

a{
    color:hsl(209, 71%, 44%) !important;
    font-size:1rem;
}

.nav-title{
    color:hsl(209, 71%, 44%);
    font-size: 1rem;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.horn{
    margin-right:1rem;
}

.open-nav{
    color: hsl(209, 71%, 44%) !important;
}
.user-photo{
    width:30px;
    border-radius: 50%;
    margin-right:10px;
    border: 1px solid hsl(209, 71%, 44%);
}
.profile-div{
    display:flex;
    flex-direction: row;
}