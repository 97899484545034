.logo{
    margin-top:10%;
}

.home-post{
    background-color:#323740;
}
.home{
    background-color: #282c34;
    height: 107vh;
}

.project-list{
    list-style-type: circle;
    padding-left:2%;
}
.header{
    color: hsl(209deg, 71%, 44%) !important;
}