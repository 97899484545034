.page-title{
    color: hsl(209, 71%, 44%);
    font-size:2rem;
    padding:4vh;
}

.error-msg{
    min-height:100vh;
    flex-direction: column;
    display:flex;
    justify-items: center;
}

.character-pic{
    border-radius: 25%;
}

.css-z7uhs0-MuiStepConnector-line {
    display:none !important;
}

.character-select{
    padding-bottom:5rem;
}
.popup-div{
    margin:auto;
    width: 90%;
    text-align: center;
}
.link-h1{
    margin:20px 0 20px 0;
}

.link-p{
    margin-bottom: 20px;
}