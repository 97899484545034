.form-header {
    background-color: #282c34;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    justify-content: center;
}

.space{
    margin-top: 2rem;
}

.link{
    margin-bottom:1rem;
    width:100%;
    text-align: right;
}

.horn{
    color:hsl(209, 71%, 44%);
    &:hover{
        background-color: #323740;
        cursor: pointer;
    }
}

.dx-form-group-caption{
    color:hsl(209, 71%, 44%) !important;
}