.dark {
    background-color: #282c34 !important;
    min-height: 100vh;
}

.card-bg{
    background-color: #323740;
}

.brd-clr{
    border-color: #c6cace ;
}

.icon{
    margin-left:5px;
    &:hover{
        background-color: #282c34;
        cursor: pointer;
    }
}

.job-img{
    height:50px;
}
.character-info-p{
    font-size:1.5rem;
    margin-bottom:10px;
}
.player-avatar{
    border-radius: 50%;
}
.job-list{
    display:flex;
    justify-content: center;
    flex-direction: column;
}